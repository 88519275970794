import { graphql } from 'gatsby'
import * as React from 'react'

import Content from '../components/Content'
import Main from '../components/Main'
import Seo from '../components/seo'

const AitohiusperuukkiPage = ({ data, location }) => {
  const { page } = data

  return (
    <Main>
      <Seo metadata={page.metadata} />
      <Content content={page.content} />
    </Main>
  )
}

export default AitohiusperuukkiPage

export const query = graphql`
  query AitohiusperuukkiQuery {
    page: sanityPage(id: { eq: "-bb4396e1-297f-58e7-82e7-fa1e09c25276" }) {
      ...contentFragment
    }
  }
`
